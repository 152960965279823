exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-galerie-tsx": () => import("./../../../src/pages/galerie.tsx" /* webpackChunkName: "component---src-pages-galerie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-schauspiel-tsx": () => import("./../../../src/pages/schauspiel.tsx" /* webpackChunkName: "component---src-pages-schauspiel-tsx" */),
  "component---src-pages-tanz-tsx": () => import("./../../../src/pages/tanz.tsx" /* webpackChunkName: "component---src-pages-tanz-tsx" */),
  "component---src-pages-theaterpaedagogik-tsx": () => import("./../../../src/pages/theaterpaedagogik.tsx" /* webpackChunkName: "component---src-pages-theaterpaedagogik-tsx" */),
  "component---src-pages-ueber-mich-tsx": () => import("./../../../src/pages/ueber-mich.tsx" /* webpackChunkName: "component---src-pages-ueber-mich-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */)
}

